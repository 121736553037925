/* eslint-disable max-lines-per-function */
import { api } from "jiffy-api";
import { until } from "common/utils/helpers/delay";

const $getProductPageContainer = () => document.getElementById("product-page-container");
const $getMicrodata = () => document.getElementById("microdata");
const $getMetaProductName = () => $getMicrodata()?.querySelector('meta[itemprop="name"]');
const $getMetaBrand = () => $getMicrodata()?.querySelector('meta[itemprop="brand"]');
const $getMetaCatalogNumber = () => $getMicrodata()?.querySelector('meta[itemprop="sku"]');
const $getBusinessLine = () => document.getElementById("product-business-line");

const getFavoriteItems = async productSlug => {
  window.jiffy = window.jiffy || {};

  // return immediately when already fetched
  if ("favoriteItems" in window.jiffy) {
    return window.jiffy.favoriteItems;
  }

  // if being fetched, wait until fetched or reached wait timeout
  if (window.jiffy.fetchingFavoriteItems) {
    const favoriteItemsFetched = await until(() => "favoriteItems" in window.jiffy);
    if (favoriteItemsFetched) {
      return window.jiffy.favoriteItems;
    }
  }

  // when currently not fetching the favorite items or has reached wait
  // timeout (not successfully awaited), fallback to fetching favorite
  // items
  window.jiffy.fetchingFavoriteItems = true;
  try {
    const data = await api.favorites.getFavoriteItems({
      product_slug: productSlug
    });
    if (data.success) {
      window.jiffy.favoriteItems = data.items;
      window.jiffy.monitoredFavoriteItems = data.monitored_items;
      window.jiffy.fetchingFavoriteItems = false;
      return data.items;
    }
  } catch (error) {
    window.jiffy.fetchingFavoriteItems = false;
    window.Honeybadger?.notify("Error fetching favorite items", {
      params: {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        product_slug: productSlug
      }
    });
  }

  return [];
};

const isFavorited = async (color, productSlug) => {
  const favoriteItems = await getFavoriteItems(productSlug);
  return favoriteItems.includes(color);
};

export default async function getVariantInfo(variantId) {
  const variantInput = document.querySelector(
    `.js-quantity-form-input[data-variant-id='${variantId}'], .js-grid-input[data-variant-id='${variantId}']`
  );
  const {
    size = "",
    isStandardSize = false,
    amount: sourcePrice = "",
    color = "",
    sku = "",
    currency = "",
    fbData = "",
    fbEventId = "",
    compositionId = "",
    isLoggedIn = "",
    fulfillmentGroup = "",
    compositionInventoryRank = "",
    businessLine = "",
    image = "",
    colorVariantIds = "[]"
  } = variantInput?.dataset || {};
  const softnessScore = parseFloat(variantInput?.dataset?.compositionSoftnessScore) || null;

  let colorVariantIdsArr;
  try {
    colorVariantIdsArr = JSON.parse(colorVariantIds);
  } catch (error) {
    colorVariantIdsArr = [];
  }

  const {
    realProductId: productId,
    productId: productSlug,
    productCategory
  } = $getProductPageContainer()?.dataset || {};
  const isFavorite = await isFavorited(color, productSlug);
  const productName = $getMetaProductName()?.content;
  const brand = $getMetaBrand()?.content;
  const catalogNumber = $getMetaCatalogNumber()?.content;
  const price = sourcePrice.replace("$", "");
  const { adwordsCategory } = $getMicrodata()?.dataset || {};
  const productAdwordsName = [catalogNumber, brand, productName, color].join(" ");
  const itemGroupId = [catalogNumber, color].join(" ");

  return {
    product: {
      id: productId,
      item_group_id: itemGroupId,
      name: productAdwordsName,
      category: productCategory,
      adwords_category: adwordsCategory,
      brand,
      business_line: businessLine || $getBusinessLine()?.value,
      image
    },
    size,
    isStandardSize: isStandardSize === "true",
    color,
    price,
    sku,
    compositionInventoryRank,
    currency,
    fbData,
    fbEventId,
    compositionId,
    isLoggedIn,
    fulfillmentGroup,
    favorite: isFavorite,
    softnessScore,
    position: colorVariantIdsArr.indexOf(Number(variantId)) + 1
  };
}
